<template>
    <div class="testovi">
        <section id="basic-tabs-components">
            <div class="row match-height">
                <!-- Basic Tabs starts -->
                <div class="col-xl-12 col-lg-12">
                    <div class="card-body">
                        <ul class="list-group">
                            <li v-for="Test in TestoviPrva" :key="Test.id" v-on:click="TestPolaganje(Test)" class="list-group-item" v-bind:class="{'correct':Test.polagao&&Test.polozio,'incorrect':Test.polagao&&!Test.polozio}">{{Test.name}}
                                <svg v-if="Test.polagao&&Test.polozio" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                <svg v-if="Test.polagao&&!Test.polozio" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>
<script>
    import api from '../api'
  export default {
    name: 'testovi',
    components: {
    },
    data() {
        return {
        Testovi:null,
        TestoviPrva:null,
        UserId:localStorage.getItem("uid")
      }
    },
    mounted(){
        this.GetTestovi().then(data => {
            console.log(data.data.Tests);
            let TestoviAll=data.data.Tests;
            this.TestoviPrva = TestoviAll.filter(Test => Test.type =="help");
            this.Testovi = TestoviAll.filter(Test => Test.type =="teory");
            this.loading=false;
        });
    },
    methods:{
        async GetTestovi() {
            return api.GetTestovi();
        },
        TestPolaganje(Test){
            this.$router.push({ name: 'TestoviPolaganje', params: { id: Test.id,uid:this.UserId } })
        }
    }
  }
</script>
<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
    li.nav-item {
        list-style-type: none;

    }
    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: red;
        background-color: transparent;
        border-color: #DAE1E7 #DAE1E7 transparent;
    }
    .nav-tabs .nav-link {
        color: red;
    }
    .nav-tabs .nav-link:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: linear-gradient(
                        30deg
                , red, rgba(173 , 51, 51, 1)) !important;
        transition: transform 0.3s;
        transform: translate3d(0, 150%, 0);
    }

        .card-body {
            padding: 0px;
            ul.list-group {
                color: red;
                font-weight: bold;
            }
            li.list-group-item.incorrect {
                background: #f44336;
                color: white;
            }
            li.list-group-item.correct {
                background: #8bc34a;
            }
        }

</style>
